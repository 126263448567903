<template>
  <div>
    <Loading :show="!pageVisible"/>
    <div class="van-content" v-if="pageVisible">
      <div>
        <img src="../assets/image/logo.png" class="login_img">
      </div>
      <div>
        <img src="../assets/image/login_bg.png" style="width:100%;height:100%;">
      </div>
      <van-form @submit="onSubmitStep1">
        <div style="margin: 14px;" v-if="!stepCaptchaVisible">
          <van-field
            v-model="email"
            required
            type="text"
            name="用户名"
            label="用户名"
            placeholder="请输入登录邮箱"
            :error-message="emailError"
          />
        </div>
        <div style="margin: 14px;" v-if="!stepCaptchaVisible">
          <van-field
            v-model="value"
            required
            autocomplete
            type="password"
            name="密码"
            label="密码"
            placeholder="请输入密码"
            :error-message="valueError"
          />
        </div>
        <twoStepCaptcha
          :visible="stepCaptchaVisible"
          @success="stepCaptchaSuccess"
          @cancel="stepCaptchaCancel"
        ></twoStepCaptcha>
        <div style="margin: 34px 14px">
          <van-button type="info" native-type="submit">登录</van-button>
        </div>
      </van-form>
    <div class="footer">上海捷州机电工程有限公司</div>
    </div>
  </div>
</template>

<script>
import { login } from "./../api/login";
import twoStepCaptcha from "./../components/twoStepCaptcha";
import { Dialog, Toast } from "vant";
import Loading from "./../components/loading";
import Store from "store";
import store from "./../store";
export default {
  name: "index",
  components: {
    twoStepCaptcha
  },
  data() {
    return {
      stepCaptchaVisible: false,
      loginSlide: false, //人机检查
      email: "", //手机号
      emailError: "",
      value: "", //密码
      valueError: "",
      tel: "",
      pageVisible: false
    };
  },
  beforeMount() {
    let openid= Store.get('openid') || false
    if(openid){
      // 自动登录
      this.loginNow();
    }
  },
  methods: {
    onHidden() {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      //this.props.show = false;
    },
    async onSubmitStep1() {
      //必填过滤
      let reg = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (this.email == "") {
        this.emailError = "请输入登录邮箱";
        return false;
      } else if (reg.test(this.email)) {
        this.emailError = "邮箱格式错误，请检查";
        return false;
      } else if (this.value == "") {
        this.valueError = "请输入密码";
        return false;
      }
      // 启动人机检查校验
      if (this.loginSlide === false && this.email != "" && this.value != "") {
        this.stepCaptchaVisible = true;

        setTimeout(() => {
          this.loading = false;
        }, 600);
        return;
      }
      //手机注册验证码
      //发送消息
      this.loginNow();
    },
    async loginNow() {
      var _this = this;
      this.pageVisible = false
      var nonce_str = Store.get('openid')
      let res = await login({ username: _this.email, password: _this.value, nonce_str: nonce_str });
      console.log('执行的结果', res)
      if (res.code == 200) {
        //console.log('登录情况如下', res.data)
        _this.setLoginAuth(res.data)
        //进入首页，首页为角色跳转页/
        setTimeout(() => {
          window.location.href = "/product";
        }, 1000);
      } else {
        if(res.error){
          Dialog.alert({
            title: "消息窗口",
            message: res.error
          }).then(() => {});
        }
        this.pageVisible = true
        this.value = "";
        this.valueError = "";
        this.stepCaptchaCancel()
      }
    },
    //记录token
    setLoginAuth(data){
        //记录登录
        const authKey = data.authKey
        const sessionId = data.sessionId
        const userInfo = data.userInfo?data.userInfo:{}
        const authList = data.authList.crm?data.authList.crm:{}
      //---——发现新authKey则进行用户注入----
      if(authKey){
        // store.commit('user/saveUserToken', authKey)
        Store.set('authKey', authKey)
      }
      if(sessionId){
        // store.commit('user/saveUserSessionId', sessionId)
        Store.set('sessionId', sessionId)
      }
      //------end----------------------//
      Store.set('userInfo', userInfo)
      Store.set('authList', authList)
      // store.commit('user/saveUserInfo', userInfo)
      // store.commit('user/saveAuthList', authList)
    },
    stepCaptchaSuccess() {
      this.loginSlide = true;
      // this.stepCaptchaVisible = false
      // 再次提交数据
      this.loginNow();
    },
    stepCaptchaCancel() {
      this.loginSlide = false;
      this.stepCaptchaVisible = false;
      this.loading = false;
    }
  },
  watch: {}
};
</script>

<style scoped lang="less">
.van-content {
  margin: 40px 18px 0;
}

.login_img {
  width: 148px;
  height: auto;
  position: absolute;
  left: 40px;
  top: 30px;
}
.van-cell {
  background-color: #f3f5f8;
}
.van-button--info {
  width: 100%;
  height: 45px;
  background: linear-gradient(105deg, #648ac2 0%, #46558c 100%);
  border-radius: 5px;
  border: #648ac2;
  text-align: center;
}
.footer {
  margin-top: 70px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #bac3cd;
  text-align: center;
}
</style>

<template>
  <!-- 两步验证 -->
  <div style="margin: 14px;" v-if="visible">
    <slide-verify
    ref="slideblock"
    :l="40"
    :r="10"
    :w="325"
    :h="80"
    :imgs="puzzleImgList"
    slider-text="请向右滑动"
    @success="onSuccess"
    @fail="onFail"
    @refresh="onRefresh"></slide-verify>
   </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'twoStepCaptcha',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: null,
      puzzleImgList: [
        'https://img.sayones.cn/slideimg0.jpg',
        'https://img.sayones.cn/slideimg1.jpg',
        'https://img.sayones.cn/slideimg2.jpg',
        'https://img.sayones.cn/slideimg3.jpg',
        'https://img.sayones.cn/slideimg4.jpg',
        'https://img.sayones.cn/slideimg5.jpg'
      ]
    }
  },
  methods: {
    onSuccess () {
      this.$emit('success')
    },
    onFail () {
      Toast('人机滑动验证失败，请重新操作!');
      this.$emit('cancel')
    },
    onRefresh () {
      this.$refs.slideblock.reset()
      this.$emit('cancel')
        // this.msg = ''
    }
  }
}
</script>
<style>
.slide-verify-slider{
  margin-top: 0!important;
}
</style>

<style lang="scss" scoped>
</style>
